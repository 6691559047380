@import "src/styles/variables";

.chat-attachments {
  .info-drawer-attachments {
    position: relative;
    a {
      position: absolute;
      display: block;
      top: 0;
      left: 0;
      height: 110px;
      width: 100%;
      z-index: 1;
    }
  }
  .attachment-links{
    margin-top: 15px;
    padding: 10px;
    color: #000000;
    background: #F4F4F4 0 0 no-repeat padding-box;
    border-radius: 10px;
  }
}