@import "src/styles/variables";

.article-details {
  padding-bottom: 40px !important;
  position: relative;
  min-height: 100vh;

  .article-details__banner-image {
    height: 400px;
    margin-top: 25px;

    img {
      width: 100%;
      height: 400px;
      object-fit: cover;
    }
  }

  .edit-btn {
    position: absolute;
    top: 7rem;
    right: 1rem;
    z-index: 1;
    cursor: pointer;
    background: white;
    padding: .25rem .5rem;
    border-radius: 100px;
    transition: all 300ms;

    &:hover {
      transform: scale(1.1);
    }

    i::before {
      color: #406481;
      transform: rotate(90deg);
    }
  }

  .article-details__wrapper {
    padding: 50px 63px;
    background: #FFFFFF 0 0 no-repeat padding-box;
    box-shadow: 10px 20px 40px 20px #E2E2E229;

    .article-details__publish-info {
      font-size: 11px;

      .publish-info__label {
        color: #C3C3C3;
      }

      .publish-info__value {
        margin-left: 8px;
        color: #0B0B0B;
        font-weight: 600;
      }

      .ant-divider {
        border-color: #707070 !important;
        margin: 0 20px;
      }
    }

    .article-details__title {
      margin-top: 15px;
      font-size: 40px;
      font-weight: 700;
      color: #000000;
      line-height: 52px;
    }

    .article-details__tags {
      margin-top: 25px;
    }

    .article-details__content__wrapper {
      margin-top: 25px;
      margin-bottom: 30px;

      .article-details__content__title {
        font-size: 25px;
        font-weight: 700;
        color: #000000;
        margin-bottom: 15px;
      }

      .article-details__content {
        color: #4f4f4f;
        margin-bottom: 30px;
      }
    }
  }

  .spinner-loader {
    top: 0;
    bottom: 0;
    margin: auto;
    left: 55%;
  }
}