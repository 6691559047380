@import "src/styles/variables";

.location-form {
  width: 32.5vw !important;
  .hospital-form__field{
    width: 100% !important;
  }
  .hospital-form__actions {
    .hospital-form__action {
      width: auto !important;
    }
  }
}