
.journey-event-modal{
  width: 80vw !important;
  .journey-event-details__header{
    display: flex;
    justify-content: space-between;
  .journey-event-details__name{
    position: relative;
    z-index: 2;
    font-weight: bold;
    font-size: 22px;
    width: 30%;
    .journey-event-details__name-text{
      margin-right: 10px;

    }
    .journey-event-details__status{
      display: inline-block;
      background: #EFEFEF;
      border-radius: 8px;
      color: #000;
      font-size: 15px;
      font-weight: 600;
      padding: 5px 13px;
      vertical-align: middle;
      button {
        margin-left: 5px;
        &.ant-switch {
          min-width: 30px;
          height: 18px;
        }
        &.ant-switch-checked {
          background-color: #42C477;
          .ant-switch-handle {
            left: calc(100% - 14px - 2px);
          }
        }
        .ant-switch-handle {
          width: 14px;
          height: 14px;
        }
      }
    }
  }

  .journey-event-details__patient{
    margin-top: -50px;
  }
  .journey-event-details__count{
    margin-top: 13px;
    color:#4F4F4F;
    font-size: 13px;
    .journey-event__visit{
      margin-right: 10px;
      vertical-align: middle !important;
    }
  }
}

.journey-event-details__tab{
  margin-top: -5px !important;
  position: relative;
  z-index: 1;
  .ant-tabs-nav-wrap{
    justify-content: right !important;
    .ant-tabs-tab{
      .ant-tabs-tab-btn{
        font-size: 14px !important;
      }
      .icon{
        font-size: 18px;
        margin-right: 7px;
        vertical-align: middle;
      }
      &:hover{
        color: #000000;
      }
    }
    .ant-tabs-tab-active{
      background: #406481 !important;
      .ant-tabs-tab-btn,.icon:before{
        color: #FFFFFF !important;
      }
    }
  }
}
}