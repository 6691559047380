@import "src/styles/variables";

.hospital-form {
  width: 65vw !important;

  .ant-modal-content {
    padding: 2vw 3vw !important;
  }

  .hospital-form__header {
    font-size: 17px;
    font-weight: 700;
    color: $secondary-color;
  }

  .hospital-form__row {
    width: 100%;
    margin-top: 20px;
  }

  .hospital-form__field {
    display: inline-block;
    vertical-align: top;
    width: 47%;
    margin-right: 6%;

    
    &:nth-child(even) {
      margin-right: 0;
    }

    .input-field .ant-input {
      border-radius: 4px !important;
    }

    &.textarea, &.tags{
      width: 100%;
    }
    
    img {
      width: 50%;
      object-fit: cover;
      margin-right: 2%;
    }

    &.full-width {
      width: 100%;
      margin-bottom: 25px;
    }

    .image-upload {
      position: absolute !important;
      height: 150px !important;
      opacity: 0;
      cursor: pointer;
    }

    .uploaded-image {
      object-fit: contain;
      width: 200px;
      height: 200px;
      margin: auto;
      border-radius: 50%;
    }
  }

  .hospital-form__actions {
    text-align: right;
    margin-top: 20px;

    .hospital-form__action {
      margin-left: 20px;
      height: 52px !important;
      width: 12%;
      font-size: 16px !important;
    }
  }
}