@import "src/styles/variables";

.journey-detail-header {
  background: url("../../../../assets/header.png") no-repeat center;
  background-size: cover;
  border-radius: 12px;
  color: #FFFFFF;
  padding: 40px 50px 40px 50px;

  .journey-detail-header__title {
    margin-bottom: 10px;
    position: relative;

    .go-back,
    .journey-detail__name {
      color: #FFFFFF !important;
      display: inline-block;
      width: auto;
    }

    .journey-detail__name {
      margin-left: -5px;
      font-weight: bold;
      font-size: 18px;
    }

    .edit-btn {
      position: absolute;
      top: 0;
      right: 0;
      cursor: pointer;
      background: white;
      padding: .25rem .5rem;
      border-radius: 100px;
      transition: all 300ms;

      &:hover {
        transform: scale(1.1);
      }

      i::before {
        color: #406481;
        transform: rotate(90deg);
      }
    }
  }

  .journey-detail-header__desc,
  .journey-detail-header__team-info {
    margin-left: 40px;
  }

  .journey-detail-header__desc {
    color: #E1E1E1;
    width: 90%;
    font-size: 16px;
    span,
    p {
      word-break: break-all;
      margin-bottom: 10px;
    }
    pre{
      margin-bottom: 10px;

    }
    .text-overflow {
      height: 40px;
    }

    .view-more {
      cursor: pointer;
    }
  }

  .journey-detail-header__team-info {
    margin-top: 30px;

    .journey-detail-header__team-title {
      color: #FFFFFF;
      font-size: 16px;
    }

    .collapse-members,
    .journey-detail-header__staff {
      background: #FFFFFF26 0 0 no-repeat padding-box;
      border-radius: 12px;
      margin: 10px 10px 0 0;
      display: inline-block;
      width: auto;
      vertical-align: top;
      padding: 5px 10px 5px 5px;
      font-size: 13px;
      cursor: pointer;

      .journey-detail-header__staff__image,
      .journey-detail-header__staff__details {
        display: inline-block;
        vertical-align: middle;
      }

      .journey-detail-header__staff__image {
        // width: 20%;
        margin-right: 5px;
      }

      .journey-detail-header__staff__details {
        .staff-name {
          width: fit-content;
          max-width: 100px;
          white-space: nowrap;
          text-overflow: ellipsis;
          overflow: hidden;
          font-size: 15px;
        }

        .staff-spl {
          font-size: 12px;
        }
      }
    }

    .collapse-members {
      margin: 1rem 0;
      padding: .5rem .75rem;
      border-radius: 100px;
    }
  }
}