@import "src/styles/variables";

.journey-template-step-form {
  .journey-template-step__field {
    margin-bottom: 15px;

    &.ant-row{
      justify-content: space-between;
    }
    .ant-input {
      border-radius: 3px;
    }

    .ck.ck-editor__main>.ck-editor__editable {
      min-height: 150px;
    }
  }

  .schedule-field {

    .input-field,
    .dropdown-field {
      display: inline-block;
      vertical-align: top;
      width: 18%;

      input {
        border-right: unset;
        border-bottom-right-radius: unset;
        border-top-right-radius: unset;
      }
    }

    .dropdown-field {
      width: 82%;

      .ant-select-selector {
        border-left: unset !important;
        border-bottom-left-radius: unset;
        border-top-left-radius: unset;
      }
    }
  }

  .journey-template-step__actions {
    position: absolute;
    right: 0px;

    .journey-template-step__action {
      position: relative;
      background: #F6F6F6 0 0 no-repeat padding-box;
      border-radius: 3px;
      padding: 10px;
      margin-bottom: 8px;

      .ant-btn {
        background: #F6F6F6 0 0 no-repeat padding-box !important;
        font-size: 15px !important;
        color: #4F4F4F !important;
        height: auto !important;
      }

      .journey-template-step__attachment-input {
        position: absolute;
        top: 0;
        left: 0;
        height: 52px !important;
        z-index: 1;
        opacity: 0;
        cursor: pointer;
      }
    }
  }
  
  .journey-template-step__disc {
    width: 60%;

    &.action_active{
      min-height: 24rem;
    }
  }
}

.journey-template-step-form__actions {
  margin: 1.5rem 0 0;
  // position: absolute;
  // bottom: -6.5rem;
  // right: -35px;
  text-align: right;
  width: 100%;

  >.ant-btn {
    padding: 8px 30px;
    height: auto;
    border-radius: 4px;

    &:first-child {
      margin-right: 15px;
    }
  }
}

.journey-template-step__attachments {
  padding-top: 15px;
  min-height: 400px;

  &.no-min-width {
    min-height: unset;
  }

  .attachment {
    display: inline-block;
    vertical-align: top;
    margin-right: 10px;
    margin-bottom: 10px;
    background: #f6f6f6;
    border-radius: 3px;
    padding: 10px;
    font-size: 12px;
    position: relative;

    .remove-icon {
      position: absolute;
      right: -5px;
      top: -5px;
      font-size: 12px;
      color: $close-color;
      width: 15px;
      height: 15px;
      line-height: 12px;
      border-radius: 50%;
      text-align: center;
      background: #FFFFFF;
      cursor: pointer;
    }
  }
}