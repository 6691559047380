@import "src/styles/variables";

.chat-input {
  position: absolute;
  bottom: -50px;
  width: 90%;
  left:0;
  right: 0;
  margin: auto;
  border-radius: 25px;
  box-shadow: 0 2px 55px #00000029;

  .chat-input__field{
    border-radius: 25px;
    background: #FFFFFF 0% 0% no-repeat padding-box;
    height: 50px;
    .ant-input{
      padding-left: 7px !important;
    }
    .chat-input__icon{
      font-size: 19px;
      padding: 0 10px;
      cursor: pointer;
      &.false{
        cursor: not-allowed;
      }
    }
    .anticon-paper-clip{
      transform: rotate(135deg);
    }
  }

  .chat-input__attachments__trigger{
    .chat-input__attachments__input{
      position: absolute;
      width: 20px;
      left: 20px;
      z-index: 1;
      opacity: 0;
    }
    input[type='file']{
      cursor: pointer !important;
      &::-webkit-file-upload-button {
        cursor:pointer;
      }
    }
  }

  .chat-input__attachment__preview{
    background: #F4F4F4 0 0 no-repeat padding-box;
    box-shadow: 0 2px 4px #0000001A;
    border-radius: 5px;
    text-align: center;
    padding: 20px;
    position: relative;
    .attachment-preview{
      width: 200px;
      height: 150px;
      object-fit: cover;
      border-radius: 10px;
    }
    .show-selected-image__remove{
      cursor: pointer;
      font-size: 15px;
      color: #FFFFFF;
      background: $danger-color;
      border-radius: 50%;
      width: 25px;
      height: 25px;
      line-height: 23px;
      text-align: center;
      position: absolute;
      top: 10px;
      left: 200px;
      right: 0;
      margin: auto;
    }
  }
  .sending-loader {
    position: absolute;
    right: 10px;
    margin: 10px;
  }
}