@import "src/styles/variables";

.chat-messages__header{
  background: #FFFFFF 0 0 no-repeat padding-box;
  box-shadow: 0 3px 12px #00000014;
  padding: 20px 30px;
  z-index: 0;
  margin-left: -10px;
  position: relative;
  .chat-messages__header__patient-image{
    position: relative;
    display: inline-block;
    vertical-align: middle;
    width: 50px;
    img{
      width: 40px;
      height: 39px;
      border-radius: 50%;
      object-fit: cover;
    }
    .chat-status-icon{
      position: absolute;
      width: 10px;
      height: 10px;
      border-radius: 50%;
      bottom: 0;
      right: 13px;
      background: #FFFFFF;
      border: 1px solid $tag-color;
      &.active{
        background: $active-color;
        border: 1px solid $active-color;
      }
    }
  }

  .chat-messages__header__patient-info{
    display: inline-block;
    margin-left: 10px;
    vertical-align: middle;
    width: calc(100% - 50px);
    .chat-messages__header__patient-name{
      font-size: 15px;
      font-weight: normal;
    }
    .chat-status{
      font-size: 13px;
      color: $tag-color;
      &.active{
        color: $active-color;
      }
    }
  }

  .chat-messages__search{
    width: 100%;
    margin-top: 8px;
    text-align: right;
    .search-input{
      width: 0;
      border: unset;
      transition: width 500ms ease-in;
    }
    .ant-input-affix-wrapper-focused{
      box-shadow: unset !important;
    }
    &.show{
      .search-input {
        width: 75%;
        border-bottom: 1px solid $tag-color;
        transition: width 500ms ease-in;
      }
    }
    .chat-messages__search__toggle{
      cursor: pointer;
      color: #888585;
      font-size: 16px;
      margin-left: 8px;
    }
  }

  .chat-messages__info-trigger{
    margin-top: 12px;
    margin-left: 10px;
    font-size: 20px;
    cursor: pointer;
  }
}