@import "src/styles/variables";

.journey-template-carousel-big__modal {
  .ant-modal-content {
    background: transparent !important;
    box-shadow: unset !important;
  }

  .journey-template-carousels__navigator {
    border-color: #FFFFFF !important;
  }

  .journey-template-carousels__description {
    color: #FFFFFF;
    text-align: center;
    margin-top: 20px;
  }

  .ant-modal-close-x {
    top: -5em !important;
    right: -25em !important;
    background: transparent !important;
    font-weight: bold;

    .anticon {
      font-size: 20px;
    }
  }

  .journey-template-carousels__image {
    img {
      width: 33vw;
      max-height: 70vh;
      object-fit: contain;
    }
  }
}

.ant-modal-wrap {
  background: #00000086 !important;
}