@import "src/styles/variables";

.chat-panel {
  position: relative;
  margin-top: 20px;
  padding: 0 40px;

  .spinner-loader{
    left: 47.5%;
  }

  .chat-panel__messages{
    position: relative;
    height: 77vh;
    overflow: auto;
    margin-bottom: 30px;
    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none;
    &::-webkit-scrollbar {
      display: none;
    }
    .ant-divider{
      .ant-divider-inner-text{
        font-size: 14px;
        color: $chat-time-color;
      }
    }
  }

  .align-left,.align-right{
    display: flex;
    justify-content: flex-start;
  }
  .align-right{
    justify-content: flex-end;
  }

  .chat-panel__bubble__general{

  }

  .chat-panel__bubble{
    position: relative;
    font-size: 16px;
    border-radius: 5px;
    max-width: 70%;
    margin-bottom: 10px;

    .sender-info{
      background: #FFFFFF 0 0 no-repeat padding-box;
      padding: 15px 5px;
      border-radius: 0 0 0 10px;
      .sender-info__image{
        width: 40px;
        float:left;
        img{
        object-fit: cover;
        width: 30px;
          height: 30px;
          border-radius: 50%;
        }
        &.image__right{
          float: right;
        }
      }
      .sender-info__name{
        font-weight: bold;
        font-size: 15px;
        color: #000000;
        margin: 0 10px;
      }
    }


    .chat-panel__bubble__content{
      padding: 8px 15px;
      border-radius: 10px;
      .chat-panel__bubble__media{
        width: 200px;
        height: 130px;
        border-radius: 10px;
        object-fit: contain;
        cursor: pointer;
        margin-bottom: 10px;
      }
    }

    &.left__bubble{
      color: #2a2a2a;
      background: #F4F4F4 0 0 no-repeat padding-box;
      .sender-info {
        border-radius: 0 0 0 10px;
      }
    }

    &.right__bubble{
      background: #EBF5FA 0 0 no-repeat padding-box;
      color: #2e2a2a;
      .sender-info {
        border-radius: 0 0 10px 0;
        text-align: right;
      }
    }

    &.attachment{
      background: #FFFFFF 0 0 no-repeat padding-box;
      box-shadow: 0 2px 4px #0000001A;
      color: #000000;
      text-align: center;
    }

    .sender-info__time{
      display: none;
      transition: .5s ease;
      position: absolute;
      bottom: 12px;
      color: $chat-time-color;
      font-size: 11px;
      &.time-left{
        right: -60px;
      }
      &.time-right{
        left: -60px;
      }
    }
    &:hover .sender-info__time{
      display: block;
    }
  }

  .chat-panel__scroll-trigger{
    position: absolute;
    right: 10px;
    bottom: 0;
    background: $primary-color;
    width: 30px;
    height: 30px;
    text-align: center;
    border-radius: 50%;
    box-shadow: 0 2px 4px #0000001a;
    color: #FFFFFF;
    cursor: pointer;
    .anticon{
      margin-top: 8px
    }
  }
}