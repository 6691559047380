@import "src/styles/variables";

.journey-template-header {
  background: url("../../../../assets/header.png") no-repeat;
  background-size: 100% 100%;
  padding: 30px;
  color: #FFFFFF;
  min-height: 20vh !important;
  height: fit-content !important;

  .journey-template-header__name {
    position: relative;

    .go-back,
    .name {
      color: #FFFFFF;
      display: inline;
    }

    .name {
      font-size: 18px;
      font-weight: 800;
    }


    .edit-btn {
      position: absolute;
      top: 0;
      right: 0;
      cursor: pointer;
      background: white;
      padding: .25rem .5rem;
      border-radius: 100px;
      transition: all 300ms;

      &:hover {
        transform: scale(1.1);
      }

      i::before {
        color: #406481;
      }
    }
  }
  form .journey-template-header-box{
    display: flex;
    flex-wrap: wrap;
  }

  .journey-template-header__details {
    margin: 12px 0 0 38px;

    .journey-template-header__numbers {
      display: inline-block;
      width: auto;
      vertical-align: top;
      padding: 5px 18px;
      font-size: 14px;
      background: #FFFFFF10 0% 0% no-repeat padding-box;
      border-radius: 18px;
      margin: 5px 10px 5px 0;
    }
  }

  //form styles
  .journey-template-header__field {
    display: inline-block;
    vertical-align: top;
    margin: 0 30px 0 0;

    .form-label {
      color: #FFFFFF;
      font-size: 13px;
    }

    .ant-input,
    .dropdown-field .ant-select.ant-select-multiple.ant-select-show-search .ant-select-selector,
    .ant-select .ant-select-selector {
      height: fit-content;
      width: max-content;
      min-width: 250px;
      min-height: 52px;
      max-width: 600px;
      background: transparent;
      outline: unset;
      border: 1px solid #5C7487 !important;
      border-radius: 3px !important;
      color: #FFFFFF;

      &:focus {
        box-shadow: unset !important;
      }
    }

    .ant-select-multiple .ant-select-selection-item {
      color: #406481;
    }
  }

  .journey-template-header__actions {
    text-align: right;

    .journey-template-header__action {
      margin-right: 10px;
    }

    .ant-btn-primary {
      background: white !important;
      color: #436784;
    }

    .ant-btn-text {
      background: transparent !important;
      color: #FFFFFF !important;
    }
  }
}