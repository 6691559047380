@import "src/styles/variables";

.attachment-viewer {
    display: inline-block;
    vertical-align: top;
    margin-right: 10px;
    margin-bottom: 10px;
    border-radius: 3px;
    padding: 10px;
    font-size: 12px;
    position: relative;

    .attachment-viewer__label {
      color: #52A3E9;

      .image-preview-box{
        position: relative;
        width: 50%;
      }

      .attachment-box, .options__preview, .options__download {
        cursor: pointer;
        display: flex;
        flex-direction: column;
        align-items: center;
      }
      .attachment-label{
        padding: 10px 0 10px 10px;
      }
      .image__options {
        width: 200%;
        display: flex;
        justify-content: space-evenly;
        transition: .5s ease;
        opacity: 0;
        position: absolute;
        top: 50%;
        left: 100%;
        transform: translate(-50%, -50%);
        -ms-transform: translate(-50%, -50%);
        text-align: center;
        
      }

      .image-preview-box:hover .attachment-viewer__preview {
        opacity: 0.1;

      }
      
      .image-preview-box:hover .image__options {
        opacity: 1;
      }
      
    }
    .remove-icon {
      position: absolute;
      right: -5px;
      top: -5px;
      font-size: 12px;
      color: $close-color;
      width: 15px;
      height: 15px;
      line-height: 12px;
      border-radius: 50%;
      text-align: center;
      background: #FFFFFF;
      cursor: pointer;
    }

    img.attachment-viewer__preview, iframe.attachment-viewer__preview {
      width: 300px;
      display: block;
      transition: .5s ease;
      backface-visibility: hidden;
    }
    img.attachment-viewer__preview-small, iframe.attachment-viewer__preview-small {
      width: 100px;
      display: block;
      transition: .5s ease;
      backface-visibility: hidden;
    }
}

.ant-modal.attachment-viewer-modal {
  .ant-modal-body {
    display: flex;
    justify-content: center;
    img {
      width: 100%;
      height: 60vh;
      object-fit: contain;
    }
    iframe {
      width: 100%;
      height: 600px;
    }
    video{
      height: 550px;
      width: auto;
    }
  }
}