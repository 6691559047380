.input-field{
  .ant-input{
    height: 52px;
    border-radius: 12px;
    padding-left: 20px !important;
  }
  textarea.ant-input{
    // height: unset !important;
  }
}

