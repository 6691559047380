@import "src/styles/variables";

.chat {
  padding-top: 0 !important;
  padding-left: 19% !important;
  width: 100% !important;

  .chat__list {
    background: #FFFFFF 0 0 no-repeat padding-box;
    box-shadow: 0 6px 12px #77777729;
    // padding: 30px 20px;
    height: 100vh;
    overflow: hidden;
    z-index: 1;
    position: relative;

    .chat__list__header {
      padding: 30px 20px 0;
      font-weight: bold;
    }

    .chat__list__search {
      margin-top: 12px;
      margin-bottom: 10px;
      padding: 0px 20px;

      .search-component {
        .ant-input-search {
          width: 100% !important;

          .ant-input-wrapper,
          .ant-input-affix-wrapper,
          .ant-input,
          .ant-input-group-addon,
          .ant-btn {
            background: #F3F3F3 0 0 no-repeat padding-box;
          }

          .ant-input-wrapper {
            border-radius: 10px;
          }
        }
      }
    }

    .chat__list__head-container {
      height: 85vh;
      overflow: auto;
      -ms-overflow-style: none;
      /* IE and Edge */
      scrollbar-width: none;

      &::-webkit-scrollbar {
        display: none;
      }
    }

    .chat__list__head {
      padding: 20px;
      // margin-top: 20px;
      cursor: pointer;
      position: relative;

      &.active {
        background: #F3F3F3;
      }

      .chat__head__new-message-indicator {
        background: $primary-color;
        position: absolute;
        right: 10px;
        top: 10px;
        width: 8px;
        height: 8px;
        border-radius: 50%;
      }

      .chat__head__patient-image {
        display: inline-block;
        vertical-align: middle;
        width: 55px;

        img {
          width: 42px;
          height: 42px;
          object-fit: cover;
          border-radius: 50%;
        }
      }

      .chat__head__patient-info {
        display: inline-block;
        vertical-align: middle;
        width: calc(100% - 55px);

        .chat__head__patient-name {
          font-weight: bold;
          font-size: 15px;
        }

        .chat__head__patient-age {
          color: $tag-color;
          font-size: 13px;
        }
      }

      .chat__head__patient-surgery {
        text-align: right;
        font-size: 11px;
      }

    }

    .ant-divider {
      margin: 0 !important;
      color: $tag-color !important;
    }
  }
}