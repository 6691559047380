@import "src/styles/variables";

.custom-toolbar {
  margin-bottom: 35px;
  user-select: none;

  .app-header{
    display: inline-block;
    vertical-align: middle;
    width: 15%;
    margin-bottom: 0;
    .app-header__left{
      width: 100%;
    }
    .app-header__right{
      width: 0;
    }
  }

  .custom-toolbar__month{
    display: inline-block;
    vertical-align: middle;
    width: 15%;
    .ant-picker{
      width: 100%;
      border: unset;
    }
    .ant-picker-input input{
      cursor: pointer !important;
    }
    .ant-picker-focused{
      box-shadow: none;
    }
  }

  .custom-toolbar__navigation{
    display: inline-block;
    vertical-align: middle;
    width: 15%;
    .custom-toolbar__icon{
      display: inline-block;
      width: 28px;
      height: 28px;
      background: #F3F3F3;
      border-radius: 50%;
      text-align: center;
      cursor: pointer;
      line-height: 30px;
      margin-right: 10px;
    }
  }

  .custom-toolbar__view-control{
    display: inline-block;
    vertical-align: middle;
    width: 55%;
    text-align: right;
    .ant-btn{
      border-radius: 6px;
      margin-left: 10px;
    }
    .ant-btn-text{
      background: #E9E9E9 !important;
    }
  }
}