@import "src/styles/variables";

.not-authorized {
 .not-authorized__content{
   margin-top: 50px;
   background: #FFFFFF 0 0 no-repeat padding-box;
   box-shadow: 0 10px 20px #AEAEAE24;
   border-radius: 8px;
   opacity: 1;
   color: $primary-color;
   font-size: 25px;
   font-weight: bold;
   padding: 50px;
   text-align: center;
   img{
     width: 25%;
     margin: 25px;
   }
 }
}