@import "src/styles/variables";

.table-component {
  .app-table {
    .ant-table {
      background: #FFFFFF 0% 0% no-repeat padding-box;
      box-shadow: 0px 10px 20px #AEAEAE24;
      border-radius: 8px !important;
    }

    .ant-table-thead>tr>th {
      background: #FFFFFF;
      color: #777C80;
      font-size: 12px;
      font-weight: 500;

      &::before {
        background: unset !important;
      }
    }

    .ant-table-tbody>tr {
      cursor: pointer;
    }

    .ant-table-tbody>tr>td {
      position: relative;
      font-size: 14px;
      color: #4F4F4F;
      border: unset !important;
      padding: 22px 16px;
    }

    .ant-table-tbody>tr>td:after {
      content: "";
      position: absolute;
      bottom: 0;
      left: 14%;
      display: block;
      width: 110%;
      border-bottom: 1px solid #F0F0F0;
    }

    .ant-table-tbody>tr>td:last-child:after {
      width: 40%;
    }

    .ant-table-thead>tr>th:first-child,
    .ant-table-tbody>tr>td:first-child {
      padding-left: 50px;
    }

    .ant-table-thead>tr>th:last-child,
    .ant-table-tbody>tr>td:last-child {
      padding-right: 50px;
    }

    .table-menu {
      transform: rotate(90deg);
      cursor: pointer;
    }

    .app-table-tags {
      text-transform: capitalize;
      font-size: 14px;
      border-radius: 8px;
      border: unset;
      padding: 4px 16px;

      &.green,
      &.completed,
      &.resolved {
        background: #A2EFA037;
        color: #12A40D;

        i:before {
          color: #12A40D;
        }
      }

      &.red {
        background: #EFA0A037;
        color: #C50F0F;

        i:before {
          color: #C50F0F;
        }
      }

      &.active {
        background: #A0C8EF37;
        color: #105ADA;

        i:before {
          color: #105ADA;
        }
      }

      &.draft {
        color: #FF5C5C;
        background-color: #FFE5E5;
      }

      &.onhold,
      &.pending {
        background: #EFE2A037;
        color: #DAA310;

        i:before {
          color: #DAA310;
        }
      }
    }

    .ant-table-tbody {
      .ant-table-cell {
        img {
          border-radius: 50%;
          width: 35px;
          height: 35px;
          object-fit: contain;
          margin-right: 8px;
        }
      }
    }
  }
}


.ant-pagination.ant-table-pagination {

  .ant-pagination-total-text {
    position: absolute;
    left: 0;

    p {
      font-size: 14px;
      margin: 0;
    }
  }

  .ant-pagination-prev,
  .ant-pagination-next {
    margin: 0;
    min-width: 24px;
    height: 24px;

    .ant-pagination-item-link {
      min-width: 24px;
      height: 24px;
      border: 0;
      line-height: 25px;

      .anticon {
        color: $primary-color;
      }
    }
  }

  .ant-pagination-item {
    margin: 0;
    min-width: 24px;
    height: 24px;
    border: none;

    a {
      margin: 0;
      min-width: 24px;
      height: 24px;
      line-height: 25px;
      border-radius: 6px;
      background-color: #fff;
      color: #000;

      &:hover {
        color: #000;
      }
    }


    &.ant-pagination-item-active {

      a {
        background-color: $primary-color;
        color: #fff;
      }
    }
  }

  .ant-pagination-jump-prev,
  .ant-pagination-jump-next {
    &:hover {

      .ant-pagination-item-link {
        .ant-pagination-item-container {

          .ant-pagination-item-ellipsis {
            opacity: 1;
          }
        }
      }
    }
  }

  .ant-pagination-options {
    .ant-select.ant-pagination-options-size-changer {
      display: none;

    }
  }
}