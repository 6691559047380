@import "src/styles/variables";

.chat-messages {
  position: relative;
}

.ant-dropdown-menu{
  background: #FFFFFF 0 0 no-repeat padding-box;
  box-shadow: 0 10px 20px #0000001F !important;
  border-radius: 8px !important;
}

.ant-dropdown-menu-title-content{
  font-size: 13px !important;
  color: #4F4F4F !important;
}