@import "src/styles/variables";

.chat-info-drawer {

}

.chat-info-drawer__drawer{
  .ant-drawer-content-wrapper{
    width: 525px !important;
  }

  .ant-drawer-header{
    background: #000000 !important;
    .ant-drawer-title{
      color: #FFFFFF !important;
      margin-left: 20px;
    }
    .ant-drawer-close{
      right: unset !important;
      left:0;
      top: -1px !important;
      color: #FFFFFF !important;
      .anticon{
        font-size: 13px;
      }
    }
  }

  .chat-info-drawer__current-user{
    text-align: center;
    margin-top: 30px;
    color: #000000;
    margin-bottom: 13px;
    img{
      width: 110px;
      height: 110px;
      border-radius: 50%;
      object-fit: cover;
    }
    .chat-info-drawer__current-user__name{
      font-weight: 700;
      font-size: 17px;
      margin-top: 5px;
    }
    .chat-info-drawer__current-user__info{
      font-weight: 600;
      font-size: 15px;
    }
  }

  .chat-info-drawer__card{
    background: #FFFFFF 0 0 no-repeat padding-box;
    box-shadow: 0 3px 12px #0000001F;
    border-radius: 8px;
    padding: 20px 25px;
    margin-top: 15px;

    .chat-info-drawer__card__title{
      font-weight: 600;
      font-size: 15px;
      color: #000000;
      .anticon{
        float: right;
        margin-top: 3px;
      }
    }

    .chat-info-drawer__group-member{
      margin-top: 18px;
      .chat-info-drawer__group-member__image{
        display: inline-block;
        width: 45px;
        vertical-align: top;
        img{
          width: 35px;
          height: 35px;
          border-radius: 50%;
          object-fit: cover;
        }
      }
      .chat-info-drawer__group-member__info{
        display: inline-block;
        width: calc(100% - 45px);
        vertical-align: middle;
        color: #4F4F4F;
        .chat-info-drawer__group-member__name{
          font-size: 15px;
          font-weight: 600;
        }
        .chat-info-drawer__group-member__role{
          font-size: 13px;
        }
      }
    }
  }
}

.chat-info-drawer__card__latest-attachments{
  display: inline-block;
  vertical-align: top;
  width: 32%;
  margin-top: 12px;
  margin-right: 2%;
  .attachment-card{
    width: 100%;
    height: 110px;
    border-radius: 10px;
  }
  &:nth-child(3n),.latest-images:nth-child(3n+1){
    margin-right: 0;
  }
}

.chat-drawer-tabs{
  .ant-tabs-nav-wrap{
    justify-content: center;
    .ant-tabs-tab + .ant-tabs-tab{
      margin-left: 80px !important;
    }
    .ant-tabs-tab-btn{
      font-weight: 600;
    }
  }
}