@import "src/styles/variables";

.staff-details {
  .staff-details__basic-info {
    background: url("../../../assets/header2.png") no-repeat;
    background-size: cover;
    border-radius: 12px;
    color: #FFFFFF;
    padding: 40px 50px;
    position: relative;

    .staff-details__navigation {

      .go-back,
      .staff-details__name,
      .staff-details__button {
        display: inline-block;
      }

      .go-back {
        color: #FFFFFF !important;
        cursor: pointer;
      }

      .staff-header__image {
        display: inline-block;
        width: 60px;

        img {
          width: 55px;
          border-radius: 50%;
          height: 55px;
          object-fit: cover;
        }
      }

      .staff-details__name {
        margin-right: 15px;
        font-size: 18px;
        font-weight: 600;
        margin-left: 12px;

        .staff-details__career-info {
          margin-left: 13px;
          background: #FFFFFF10 0% 0% no-repeat padding-box;
          border-radius: 18px;
          padding: 7px 20px;
          font-size: 13px;
          font-weight: 300;
        }
      }
    }

    .staff-details__bio {
      margin-top: 12px;
      color: #D4D4D4;
      font-size: 15px;
      margin-left: 110px;
      span,
      p {
        word-break: break-all;
        margin-bottom: 10px;
      }
      pre{
        margin-bottom: 10px;
  
      }
      .text-overflow {
        height: 40px;
      }
    }

    .staff-details__website {
      margin-left: 110px;
      margin-top: 20px;
    }

    .staff-details__edit {
      position: absolute;
      display: inline;
      background: #FFFFFF;
      border-radius: 50%;
      bottom: 12px;
      right: 15px;
      width: 30px;
      height: 30px;
      line-height: 30px;
      text-align: center;
      cursor: pointer;

      i:before {
        color: #000;
      }
    }
  }
}

.staff-details__communication {
  margin-left: 110px;
  margin-top: 30px;

  .staff-details__contact {
    display: inline-block;
    margin-right: 25px;

    .staff-details__contact-icon {
      background: #FFFFFF07;
      padding: 7px 10px;
      border-radius: 50%;
      margin-right: 8px;

      i:before {
        color: #FFFFFF;
      }
    }
  }
}

.staff-details__assigned-patients {
  margin: 50px auto;
  width: 95%;
}