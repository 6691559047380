@import "src/styles/variables";
@import '~react-big-calendar/lib/sass/styles';

.my-calendar {
  .rbc-month-view,.rbc-time-view{
    background: #FFFFFF 0 0 no-repeat padding-box;
    box-shadow: 0 5px 20px #00000024;
    border-radius: 8px;
    padding: 16px 25px;
    border: unset !important;
  }

  .rbc-month-header{
    background: #F4F7F8 0 0 no-repeat padding-box;
    border-radius: 12px;
    padding: 15px 0;
    .rbc-header{
      border: unset;
      font-weight: normal;
      color: #646464;
    }
  }

  .rbc-time-header{
    border: unset !important;
    .rbc-time-header-content{
      border: unset;
      padding: 15px 0;
      background: #F4F7F8 0 0 no-repeat padding-box;
    }
    .rbc-header{
      border: unset;
      font-weight: normal;
      color: #646464;
      .week-view-header{
        color: #B9B9B9;
        font-size: 13px;
        .week-view-header__date{
          font-size: 31px;
        }
      }
    }
    .rbc-today{
      background: none;
      .week-view-header{
        color: $secondary-color;
      }
    }
    .rbc-allday-cell{
      display: none;
    }
  }

  .rbc-time-content{
    border: unset !important;
    .rbc-today{
      background: #FFFFFF;
    }
    .rbc-timeslot-group {
      border-bottom: 1px dashed #F4F4F4 !important;
      border-left: 1px solid #EEEEEE !important;
      &:nth-child(2n){
        border-bottom: 1px solid #F4F4F4 !important;
      }
      .rbc-time-slot{
        min-height: 25px;
        border-top: unset;
        border-right: 1px solid #F4F4F4 !important;
      }
    }
    .rbc-time-gutter {
      .rbc-timeslot-group {
        min-height: 51px;
        border: unset !important;
        .rbc-time-slot {
          border: unset;
        }
        &:nth-child(2n){
          .rbc-time-slot {
            .rbc-label{
              color: #D4D4D4 !important;
            }
          }
        }
      }
    }
  }

  .rbc-time-content > * + * > * {
     border-left: 1px solid #FAFAFA !important;
  }

  .rbc-month-row{
    //justify-content: flex-end;
    border-top: 1px solid #FAFAFA !important;
    .rbc-date-cell{
      //text-align: left;
      //padding:0 0 5px 10px;
    }
    .rbc-row-bg{
      .rbc-day-bg{
        border: 1px solid #FAFAFA !important;
      }
      .rbc-off-range-bg{
        background: none;
      }
    }
    .rbc-row-content{
      font-size: 13px;
      .rbc-date-cell, .rbc-date-cell a:hover{
        color: #646464;
      }
      .rbc-off-range, .rbc-off-range a:hover{
        color: #E7E7E7 !important;
      }
    }
  }

  .rbc-event{
    background: #FFFFFF;
    border:unset !important;
    border-radius: unset;
    .rbc-event-label{
      display: none;
    }
    &.rbc-selected{
      background: #FFFFFF;
    }
    .rbc-event-content{
      min-height: 55px;
      .event-info{
        position: relative;
        border-radius: 0 0 8px 8px;
        padding: 15px 10px;
        text-align: center;
        font-size: 12px;
        margin: 0 12px;
        .event-info__patient-name{
          white-space: pre-wrap;
        }
        .event-info__time-slot{
          font-size: 10px;
        }
      }
      .event-top-border {
        width: 100%;
        height: 2px;
      }
      .event-info, .event-top-border {
        &.Sunday {
          background: #20A7C9;
        }

        &.Monday {
          background: #D8BD2C;
        }

        &.Tuesday {
          background: #20C95C;
        }

        &.Wednesday {
          background: #AC76FE;
        }

        &.Thursday {
          background: #767AFE;
        }

        &.Friday {
          background: #DE44D2;
        }

        &.Saturday {
          background: #FE9E76;
        }
      }
    }
  }

  .rbc-time-column{
    .rbc-event{
      .rbc-event-content {
        .event-info{
          padding: 15px 5px ;
        }
        .event-info__time-slot {
          margin-top: 10px;
        }
      }
    }
  }

  .calendar-loader {
    position: absolute;
    height: 100vh;
    width: 75%;
    background: #FFFFFF90;
    z-index: 5;
    .spinner-loader {
      top: 0;
      bottom: 0;
      left: 0;
      right:0;
      margin: auto;

    }
  }
}