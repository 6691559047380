.dp-chip {
    display: inline-flex;
    text-transform: uppercase;
    font-weight: bold;
    align-items: center;
    justify-content: center;
    border-radius: 100px;
    color: #8F90A6;
    background-color: #E4E4EB;

    span {
        cursor: default;
    }

    img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        border-radius: 100px;
    }

    &.mini {
        width: 35px;
        height: 35px;
        font-size: 16px;
    }

    &.small {
        width: 45px;
        height: 45px;
        font-size: 20px;
    }

    &.large {
        width: 60px;
        height: 60px;
        font-size: 24px;
    }

    &.a,
    &.h,
    &.o,
    &.v {
        color: #0754C6;
        background-color: #E5F0FF;
    }

    &.b,
    &.i,
    &.p,
    &.w {
        color: #6600CC;
        background-color: #FFE5FF;
    }

    &.c,
    &.j,
    &.q,
    &.x {
        color: #03B2F2;
        background-color: #D9F4FD;
    }

    &.d,
    &.k,
    &.r,
    &.y {
        color: #FF5C5C;
        background-color: #FFE5E5;
    }

    &.e,
    &.l,
    &.s,
    &.z {
        color: #E6B800;
        background-color: #FFFEE6;
    }

    &.f,
    &.m,
    &.t {
        color: #2EA67E;
        background-color: #D3EDE9;
    }

    &.g,
    &.n,
    &.u {
        color: #FFAB16;
        background-color: #FDF3E1;
    }
}